/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styles from './Concepts.module.css';
import { getCoursesListOfAnInstitution } from '../../../../api/institutionCourses';
import { getAllSubjectsByBoardId } from '../../../../api/InstitutionSubjects';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';
import { getListOfSubSubjects } from '../../../../api/institutionSubSubjects';
import { getinstitutionConceptsTable, uploadInstitutionConceptsTable } from '../../../../api/institutionConcepts';
import ReactLoading from 'react-loading';
import { saveAs } from 'file-saver';
import { ToastContainer, toast } from 'react-toastify';
import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

const Example = ({ type, color }) => (
  <div className="loading_animation">
    <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
  </div>
);

const SelectOptions = ({ options, label, placeHolder, handleChange, value, mandatory }) => {
  return (
    <div className={styles.containers}>
      <label htmlFor="cars" style={{ width: 'auto', display: 'block' }}>
        {label} {mandatory ? <span style={{ color: 'red', fontSize: '12px' }}>*</span> : null}
      </label>

      <select className={styles.selectMain} onChange={handleChange} value={value} id="cars">
        <option value="-1">Select {label}</option>
        {options?.map((i, index) => (
          <option key={index} value={i.id}>
            {i.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const Concepts = () => {
  const school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 9);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  const [errors, setErrors] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [optionsList, setOptionsList] = useState({
    board: [],
    course: [],
    subject: [],
    sub_subject: [],
  });
  const [selectedValues, setSelectedValues] = useState({
    board_id: '-1',
    course_id: '-1',
    subject_id: '-1',
    sub_subject_id: '-1',
  });
  const [tableData, setTableData] = useState([]);
  const [reducedData, setReducedData] = useState([]);
  const [boardupdate, setBoardupdate] = useState(false);
  const getBoardList = async () => {
    const getBoard = await getBoardsByNameInInstitution(school_id);
    setOptionsList((prevOptions) => ({ ...prevOptions, board: getBoard.data.data }));
    setBoardupdate(!boardupdate);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setReducedData(tableData.slice(startIndex, endIndex));
  };
  useEffect(() => {
    getCurrentPageData();
  }, [currentPage, tableData]);

  useEffect(() => {
    getBoardList();
  }, []);

  const recallApi = async () => {
    try {
      if (+selectedValues?.board_id > 0 && +selectedValues?.course_id < 0 && +selectedValues?.subject_id < 0) {
        await handleDropdown(selectedValues?.subject_id, 'subject');
      } else if (
        +selectedValues?.board_id > 0 &&
        +selectedValues?.course_id > 0 &&
        +selectedValues?.subject_id < 0 &&
        +selectedValues?.sub_subject_id < 0
      ) {
        await handleDropdown(selectedValues?.sub_subject_id, 'sub_subject');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdown = async (e, selectedvalue) => {
    console.log(e, selectedvalue);
    setTableData([]);
    const value = Number(e?.target?.value || e);
    setIsLoading(true);
    if (selectedvalue === 'board') {
      if (value > 0) {
        setSelectedValues((pre) => ({
          ...pre,
          board_id: value,
          course_id: -1,
          subject_id: -1,
        }));

        try {
          // PORT-IC-4: Retrieve list of courses of an institution
          const getCourse = await getCoursesListOfAnInstitution(school_id, value, Auth);

          setOptionsList((prevState) => ({
            ...prevState,
            course: getCourse?.data?.data,
          }));
        } catch (err) {
          if (err?.response && err?.response?.data) {
            console.log(err?.response?.data.message, 'error');
            //   toast.error(err.response.data.message);
          }
        }
      } else {
        setOptionsList((prevState) => ({
          ...prevState,
          course: [],
          subject: [],
          sub_subject: [],
        }));
        setSelectedValues((pre) => ({
          ...pre,
          board_id: -1,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
        }));
      }
      setIsLoading(false);
    } else if (selectedvalue === 'course') {
      if (value > 0) {
        setSelectedValues((pre) => ({
          ...pre,
          course_id: value,
          subject_id: -1,
        }));

        try {
          const getSubject = await getAllSubjectsByBoardId(school_id, selectedValues.board_id, value, '');

          setOptionsList((prevState) => ({
            ...prevState,
            subject: getSubject?.data?.data,
          }));
          setIsLoading(false);
        } catch (err) {
          if (err?.response && err?.response.data) {
            console.log(err?.response?.data.message, 'error');
            //   toast.error(err.response.data.message);
          }
        }
      } else {
        setIsLoading(false);

        setOptionsList((prevState) => ({
          ...prevState,
          subject: [],
          sub_subject: [],
        }));
        setSelectedValues((pre) => ({
          ...pre,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
        }));
      }
    } else if (selectedvalue === 'subject') {
      setIsLoading(true);
      setSelectedValues((pre) => ({
        ...pre,
        subject_id: value,
        sub_subject_id: '-1',
      }));
      // pageNum = 1;
      // default_page = 1;
      // setTreeDisplay(true);

      try {
        const getSubSUbject = await getListOfSubSubjects(school_id, selectedValues.board_id, selectedValues.course_id, value, '');

        setOptionsList((prevState) => ({
          ...prevState,
          sub_subject: getSubSUbject?.data?.data || [],
        }));
      } catch (err) {
        if (err?.response && err?.response.data) {
          console.log(err?.response?.data.message, 'error');
          //   toast.error(err.response.data.message);
        }
      }
      if (value > 0) {
        try {
          const res = await getinstitutionConceptsTable(selectedValues.board_id, selectedValues.course_id, value, -1, 1);
          setTableData(res.data?.data);
          setIsLoading(false);
        } catch (err) {
          if (err?.response && err?.response.data) {
            console.log(err?.response?.data.message, 'error');
            //toast.error(err.response.data.message);
          }

          // setNoData(true);
          setIsLoading(false);
          // setTreeDisplay(false);
        }
      } else {
        try {
          setTableData([]);
        } catch (error) {}

        setIsLoading(false);

        setSelectedValues((pre) => ({
          ...pre,
          subject_id: -1,
        }));
      }
    } else if (selectedvalue === 'sub-subject') {
      setIsLoading(true);
      setSelectedValues((pre) => ({
        ...pre,
        sub_subject_id: value,
      }));
      // pageNum = 1;
      // default_page = 1;
      // setTreeDisplay(true);
      if (value) {
        try {
          setTableData([]);
          const res = await getinstitutionConceptsTable(
            selectedValues.board_id,
            selectedValues.course_id,
            selectedValues.subject_id,
            value,
            1,
          );

          setTableData(res.data?.data);
          setIsLoading(false);
        } catch (err) {
          if (err?.response && err?.response.data) {
            console.log(err?.response?.data.message, 'error');
            toast.error(err.response.data.message);
          }

          // setNoData(true);
          setIsLoading(false);
          // setTreeDisplay(false);
        }
      } else {
        try {
        } catch (error) {}

        setIsLoading(false);

        setSelectedValues((pre) => ({
          ...pre,
          subject_id: -1,
        }));
      }
    }
  };
  useEffect(() => {
    const apicall = async () => {
      if (localStorage.getItem('conceptDropdown') && optionsList?.board?.length > 0) {
        const storeValue = JSON.parse(localStorage.getItem('conceptDropdown'));
        console.log(selectedValues);
        selectedValues.board_id = storeValue?.board_id;
        selectedValues.course_id = storeValue?.course_id;
        selectedValues.subject_id = storeValue?.subject_id;
        selectedValues.subject_id = storeValue?.sub_subject_id;

        if (+storeValue?.board_id) {
          const getCourse = await getCoursesListOfAnInstitution(school_id, storeValue?.board_id);

          setOptionsList((prevState) => ({
            ...prevState,
            course: getCourse?.data?.data,
          }));
        }
        if (+storeValue?.course_id) {
          try {
            const getSubject = await getAllSubjectsByBoardId(school_id, storeValue.board_id, storeValue?.course_id, '');

            setOptionsList((prevState) => ({
              ...prevState,
              subject: getSubject?.data?.data,
            }));
          } catch (err) {
            if (err?.response && err?.response.data) {
              console.log(err?.response?.data.message, 'error');
            }
          }
        }
        if (+storeValue?.subject_id) {
          selectedValues.subject_id = storeValue?.subject_id;
          try {
            const getSubSUbject = await getListOfSubSubjects(
              school_id,
              storeValue.board_id,
              storeValue?.course_id,
              storeValue?.subject_id,
              '',
            );

            setOptionsList((prevState) => ({
              ...prevState,
              sub_subject: getSubSUbject?.data?.data,
            }));
          } catch (err) {
            if (err?.response && err?.response.data) {
              console.log(err?.response?.data.message, 'error');
            }
          }
        }
        if (+storeValue?.sub_subject_id) {
          selectedValues.sub_subject_id = storeValue?.sub_subject_id;
        }
        if (+storeValue?.sub_subject_id > 0 || +storeValue?.subject_id > 0) {
          recallApi();
          try {
            const res = await getinstitutionConceptsTable(
              storeValue.board_id,
              storeValue.course_id,
              storeValue.subject_id,
              storeValue?.sub_subject_id,
              1,
            );
            setTableData(res.data?.data);
          } catch (error) {
            console.log(error);
          }
        }
      }
    };
    apicall();
  }, [optionsList?.board]);
  useEffect(() => {
    setCurrentPage(1);
    if (optionsList?.board.length) {
      window.localStorage.setItem('conceptDropdown', JSON.stringify(selectedValues));
    }
  }, [selectedValues]);

  const uploadConcepts = async (e) => {
    let formdata = new FormData();
    let file = e.target.files[0];
    setIsLoading(true);
    formdata.append('filetoupload', file);

    try {
      await uploadInstitutionConceptsTable(
        0,
        selectedValues.board_id,
        selectedValues.course_id,
        selectedValues?.subject_id,
        selectedValues?.sub_subject_id,
        formdata,
      );
      setIsLoading(false);
      const res = await getinstitutionConceptsTable(
        selectedValues.board_id,
        selectedValues.course_id,
        selectedValues.subject_id,
        selectedValues.sub_subject_id,
        1,
      );
      setTableData(res.data?.data);

      toast.success('File Uploaded Successfully');
    } catch (err) {
      console.log(err?.response);

      setErrors(err?.response?.data?.errors);

      setIsLoading(false);
    }
  };

  const downloadFile = async () => {
    try {
      const response = await getinstitutionConceptsTable(
        selectedValues.board_id,
        selectedValues.course_id,
        selectedValues?.subject_id,
        selectedValues?.sub_subject_id,
      );

      let filename = response.headers['content-disposition'].split('=')[1];
      var blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });

      saveAs(blob, filename);

      toast.success('file downloaded');
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  const dissableButton = () => {
    if (+selectedValues?.board_id > 0 && +selectedValues?.course_id > 0 && +selectedValues?.subject_id > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      {isLoading ? <ReactLoading type="bars" className="loadingAni" color="#DCEEDC" /> : null}
      <div
        className="containers"
        style={{ display: 'flex', alignItems: 'center', padding: '10px 15px', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <SelectOptions
            options={optionsList.board?.map((i) => ({ name: i.display_name, id: i.board_id, instId: i.institution_board_id }))}
            label={'Board'}
            placeHolder=""
            handleChange={(e) => {
              handleDropdown(e, 'board');
            }}
            value={selectedValues?.board_id}
            mandatory
          />
          <SelectOptions
            options={optionsList.course?.map((i) => ({ name: i.display_name, id: i.course_id, instId: i.institution_board_id }))}
            label={'Course'}
            placeHolder=""
            handleChange={(e) => handleDropdown(e, 'course')}
            value={selectedValues?.course_id}
            mandatory
          />
          <SelectOptions
            options={optionsList.subject?.map((i) => ({ name: i.display_name, id: i.subject_id }))}
            label={'Subject'}
            placeHolder=""
            handleChange={(e) => handleDropdown(e, 'subject')}
            value={selectedValues?.subject_id}
            mandatory
          />
          <SelectOptions
            options={optionsList.sub_subject?.map((i) => ({ name: i.display_name, id: i.institution_sub_subject_id }))}
            label={'Sub Subject'}
            placeHolder=""
            handleChange={(e) => handleDropdown(e, 'sub-subject')}
            value={selectedValues?.sub_subject_id}
          />
        </div>
        <div style={{ display: 'flex', gap: '15px' }}>
          <div className={styles.upload}>
            {dissableButton()}
            <button
              type="button"
              name="dwnloadCocepts"
              disabled={dissableButton()}
              onClick={() => {
                downloadFile();
              }}
              style={{
                borderRadius: '10px',
                width: '165px',
                height: '32px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
                background: 'white',
                border: '1px solid #dddddd ',
                fontSize: '10px',
                color: '#868686',
              }}>
              {' '}
              <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ marginRight: '10px' }} />
              Download Concepts
            </button>
          </div>

          <div className={styles.upload}>
            <input
              type="file"
              id="imagesupload"
              name="imagesupload"
              // multiple
              accept=".xlsx"
              onChange={(e) => {
                uploadConcepts(e);
                e.target.value = null;
              }}
              disabled={dissableButton()}
              style={{ display: 'none', marginRight: '5px' }}
            />
            <label
              htmlFor="imagesupload"
              style={{
                borderRadius: '10px',
                width: '165px',
                height: '32px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
                background: 'white',
                border: '1px solid #dddddd ',
              }}>
              <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ marginRight: '10px' }} />
              Upload Concepts
            </label>
          </div>
        </div>
      </div>

      <div style={{ padding: ' 0px 10px 10px 10px' }}>
        <Box component="div" className={styles.tableBox}>
          <div className={styles.table_box}>
            {isLoading === true ? (
              <Example />
            ) : (
              <div className={styles.schoolList_Table}>
                <div className={styles.schoolList_Table_head}>
                  <di className={styles.schoolList_Table_head_data_photo}>Subject</di>
                  <di className={styles.schoolList_Table_head_data_photo}>Sub Subject</di>
                  <di className={styles.schoolList_Table_head_data_photo}>Topic</di>

                  <di className={styles.schoolList_Table_head_data_photo}>Sub Topic</di>

                  <di className={styles.schoolList_Table_head_data_photo}>Concept</di>
                </div>
                {
                  <div className={styles.schoolList_Table_body}>
                    {reducedData?.length === 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontWeight: 'bold',
                        }}>
                        No Data Found
                        <span role="img" aria-label="jsx-a11y/accessible-emoji">
                          😔
                        </span>
                      </div>
                    ) : (
                      reducedData.map((elm, key) => (
                        <div key={key} className={styles.schoolList_Table_body_row}>
                          <div className={styles.schoolList_Table_body_row_td3}>{elm.subject_name}</div>
                          <div className={styles.schoolList_Table_body_row_td3}>{elm.sub_subject_name}</div>
                          <div className={styles.schoolList_Table_body_row_td3}>{elm.topic_name}</div>
                          <div className={styles.schoolList_Table_body_row_td3}>{elm.sub_topic_name}</div>

                          <div className={styles.schoolList_Table_body_row_td3}>
                            {' '}
                            {elm.concept_name || '--'}{' '}
                            {elm.concept_name_eng && elm.concept_name_eng !== '' ? `(${elm.concept_name_eng})` : null}
                          </div>
                          {/* <div className={styles.schoolList_Table_body_row_td3}>
                      {elm.pincode}
                    </div>
                    <div className={styles.schoolList_Table_body_row_td3}>
                      {elm.internet}
                    </div> */}
                        </div>
                      ))
                    )}
                  </div>
                }
              </div>
            )}
          </div>
        </Box>
        <Box className={styles.bottomBox + ' pagination'} component="div">
          <Pagination
            count={totalPages}
            page={currentPage}
            // defaultPage={default_page}
            onChange={(e, value) => {
              changePage(value);
            }}
          />
        </Box>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Concepts;
