import https from './init/https';

// PORT-ICO-4: Retrieve Concepts
export const getinstitutionConceptsTable = async (board_id, course_id, subject_id, sub_subject_id, table) => {
  try {
    let condition = [],
      cond = '';

    if (board_id) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && Number(subject_id) > 0) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (sub_subject_id && Number(sub_subject_id) > 0) {
      condition.push(`sub_subject_id=${sub_subject_id}`);
    }
    if (table) {
      condition.push(`table_only=${table}`);
    }
    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    const config = {
      responseType: table === 1 ? 'json' : 'arraybuffer', // Set response type based on table value
    };
    let res = await https.get(`/concepts${cond}`, config);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

//PORT-ICO-5: Upload Concepts
export const uploadInstitutionConceptsTable = async (institution_id, board_id, course_id, subject_id, sub_subject_id, file) => {
  try {
    let condition = [],
      cond = '';

    condition.push(`institution_id=${institution_id}`);

    if (board_id) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id) {
      condition.push(`course_id=${course_id}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }

    let res = await https.post(`/concepts/excel-import${cond}`, file);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
